import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InfoBar from '../components/info-bar'
import ParallaxEffect from '../components/parallax'

class LandingIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const landingData = get(this, 'props.data.contentfulLandingPage')

// react bootstrap layout ref: https://react-bootstrap.github.io/layout/grid/
    return (
        <>
          <Helmet>
            <title>{siteTitle}| Themed and Curated Photography Galleries| Image Collections| Image Gallery</title>
            <meta name="description" content="Features themed and curated image galleries from various locations in australia and beyond. Photography and artwork." />
            <meta name="google-site-verification" content="8emG-6BusKBTgh3I15W4nj5BnVlYnLqsK1gYAgdavDs" />
            <link rel="manifest" crossorigin="use-credentials" href="../../manifest.webmanifest" />
            <meta name="theme-color" content="#000" />
            <link href="https://fonts.googleapis.com/css?family=Lato:300,400,400italic,600,700|Raleway:300,400,500,600,700|Crete+Round:400italic&display=swap" rel="stylesheet"/>
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons&display=swap"/>
            <link rel="apple-touch-icon" sizes="180x180" href="../../apple-touch-icon.png?v=lkggaQwrXr"/>
            <link rel="icon" type="image/png" href="../../favicon-32x32.png?v=lkggaQwrXr" sizes="32x32"/>
            <link rel="icon" type="image/png" href="../../favicon-16x16.png?v=lkggaQwrXr" sizes="16x16"/>
            <link rel="mask-icon" href="../../safari-pinned-tab.svg?v=lkggaQwrXr" color="#5bbad5"/>
            <link rel="shortcut icon" href="../../favicon.ico?v=lkggaQwrXr"/>
          </Helmet>
          <div className="hero-title">
            <div>
              <h1 className="hero-heading">Light Play Images</h1>
              <p className="landing-text">Features themed and curated image galleries from various locations in Australia and beyond.</p>
              <div className="landing-button-container">
                <Link className="landing-button" to="/explore">Explore Light Play</Link>
              </div>
            </div>
          </div>
          <ParallaxEffect
            backgroundImg={landingData.backgroundImages[1].file.url}
            backgroundImgs={`${landingData.backgroundImages[0].file.url} 1900w,
                            ${landingData.backgroundImages[1].file.url} 1450w,
                            ${landingData.backgroundImages[2].file.url} 840w,
                            ${landingData.backgroundImages[3].file.url} 650w`}
            galleryClass="gallery-header-parallax"
          />
          <InfoBar/>
        </>
    )
  }
}

export default LandingIndex

//note: contentful long text entries require ItemsFieldName { itemsFieldName } to retrieve the stored data
export const pageQuery = graphql`

  query LandingIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulLandingPage {
      backgroundImages {
        file {
          url
        }
      }
    }
  }
`

/* fixed background image
  <div className="bg-image" style={{ backgroundImage: `url(${homeData.backgroundImage.file.url})`}}>
    <div className="main-title-section">
      <h1 className="main-heading">{homeData.mainTitle}</h1>
      <p className="main-heading-p">{homeData.description.description}</p>
    </div>
  </div>
*/
// for gatsby image use Img component(note the capital I)
// for regular img tags use this format:
/*
  mainHeaderImage {
    title
    description
    file {
      url
    }
  }
  previewImage {
    title
    description
    file {
      url
    }
  }
*/